import React from 'react'
import { Container } from 'reactstrap'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

import imgA from '../assets/images/sandwich.jpg'
import imgB from '../assets/images/slider_a.png'
import panorama from '../assets/images/panorama.png'

const SpitikoPage = () => {
  return (
    <Layout>
          <Helmet>
            <title>Η Εταιρεία | Σπιτικό Παπακωνσταντίνου</title>
            <meta name="description" content="Η Εταιρεία." />
        </Helmet>
      <Container className="company">
      <h1><b>Σπιτικό Παπακωνσταντίνου</b></h1>
        <p>Η εταιρεία μας ιδρύθηκε το 2002, με στόχο να προσφέρει προϊόντα υψηλής ποιότητας και καλής εξυπηρέτησης.Με εφόδια την εμπειρία , την εξειδίκευση και την γνώση των στελεχών της, η εταιρεία επιθυμεί να αποτελέσει αρωγό σε κάθε βήμα της επιχειρηματικής προσπάθειας, παρέχοντας άρτιες και αποτελεσματικές λύσεις.</p>
        <p>Πιστοποιημένη κατα το πρότυπο <b>ISO 22 000</b>, η ασφάλεια των τροφίμων θεωρείται ώς ένα από τα σημαντικότερα πλεονεκτήματα όλων των συνεργατών μας.</p>
        <img src={panorama} className="fullImage" alt="ΤΟ ΕΡΓΑΣΤΗΡΙΟ"></img>
        <h3><b>Μπάρες</b></h3>
        <div class="row">
        <div class="col-md-6"><img className="fullImage" src={imgB} alt="ΜΠΑΡΕΣ"></img></div>
        <div class="col-md-6"><p>Ο τρόπος ζωής με αυξημένα ωράρια και λίγες ώρες ελεύθερου χρόνου, αναγκάζει πολλούς να στραφούν στην κατανάλωση γρήγορων υγιεινών σνακ, τα οποία να πληρούν τις προϋποθέσεις για προϊόντα υγιεινά, υψηλής ενέργειας και χαμηλού γλυκαιμικού φορτίου.
Σπιτικό σημαίνει.. χειροποίητο!!</p>
<p>Οι χειροποίητες μπάρες Σπιτικό αποτελούν το ιδανικό υγιεινό σνακ για την διατροφή των παιδιών, για άτομα που αθλούνται, καθώς και για άτομα που ακολουθούν μια ισορροπημένη διατροφή.
</p><p>Οι μπάρες μας ειναι το ιδανικότερο σνακ για πρωινό, δεκατιανό ή απογευματινό και τα πλεονεκτήματά:πλούσιες σε ενέργεια πλούσιες σε φυτικές ίνες περιέχουν αντιοξειδωτικά χαμηλές σε θερμίδες χωρίς προσθήκη ζάχαρης και συντηρητικά χαμηλό γλυκαιμικό φορτίο χαμηλά κορεσμένα λιπαρά.
        </p></div>
        </div>

         <h3><b>Σάντουιτς</b></h3>
        <div class="row">
        <div class="col-md-6"><p>Το προϊόν μας δεν είναι απλά ένα σάντουιτς... Τα προϊόντα μας ειναι προϊόντα παραδοσιακών φούρνων και όχι βιομηχανικά. Τα αλλαντικά και τα τυροκομικά που χρησιμοποιούμε είναι αρίστης ποιότητας.</p>
        <p> Ολες μας οι πρώτες ύλες είναι πιστοποιημένα προϊόντα, τα οποία ελέγχονται τακτικά με μικροβιολογικές αναλύσεις, όπως επίσης τα τελικά προϊόντα μας για την διασφάλιση ποιότητας τους.</p><p><b>Παρέχουμε ημερήσια παράδοση σε καταστήματα ψιλικών, αναψυκτήρια, ιντερνετ καφέ, κυλικεία, αυτόματους πωλητές καθώς και σε αθλητικούς και πολιτιστικούς φορείς</b>.
        </p></div>
        <div class="col-md-6"><img className="fullImage" src={imgA} alt="ΣΑΝΤΟΥΙΤΣ"></img></div>
        </div>
      </Container>
    </Layout>
  )
}

export default SpitikoPage